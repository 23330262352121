import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';

const BmwProducts = Loadable(lazy(() => import('views/pages/bmwProductPage')));
const BmwTrack = Loadable(lazy(() => import('views/pages/bmwProductPage/trackingTool')));
const BmwTrackSearchBar = Loadable(lazy(() => import('views/pages/bmwProductPage/bmwTrackBar')));

const BMWroutes = {
  path: '/',
  element: (
    <NavMotion>
      <MinimalLayout />
    </NavMotion>
  ),
  children: [
    {
      path: '/',
      element: <BmwTrackSearchBar />
      // element: <BmwProducts />
    },
    {
      path: '/trackingTool/:id',
      element: <BmwTrack />
    },
    {
      path: '/trackingTool/*',
      element: <BmwTrackSearchBar />
    },
    {
      path: '/track'
    }
  ]
};

export default BMWroutes;
